@import url(https://fonts.googleapis.com/css?family=Gloria+Hallelujah);

*{box-sizing: border-box;}

* {
	overflow: hidden;
	user-select: none !important;
	touch-action: manipulation !important;
	-webkit-user-select: none !important;
	-webkit-touch-callout: none !important;
}

body {
	margin: 0; padding: 0;

	width: 100dvw;
	height: 100dvh;
	overflow: hidden;
	-webkit-user-select: none !important;
	user-select: none !important;
	-webkit-touch-callout: none !important;
	/* touch-action: ; */
}

.container {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	bottom: 0;
}

canvas {
	height: 100%;
	width: 100%;
	/* bottom: 50px */
	position: absolute;
	/* display: block; */
	/* background: url(https://i.imgur.com/Y0BMP.png) top left; */
}

#scoreBoard {
	width: 420px;
	height: 50px;
	background: rgba(182, 200, 220, 0.7);
	position: absolute;
	top: -3px;
	left: 0;
	z-index: -1;
	border-image: url(https://i.imgur.com/5BBsR.png) 100 5 round;
}

#scoreBoard p {
	font-size: 20px;
	padding: 0;
	line-height: 47px;
	margin: 0px 0 0 5px;
}

img {display: none}

#mainMenu, #gameOverMenu {
	height: 100%;
	width: 100%;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

#gameOverMenu {
	visibility: hidden;
}

h2, h3, h1 {font-weight: normal}
h1 {
	font-size: 60px;
	color: #5a5816;
	transform: rotate(-10deg);
	margin: 0px;
}

h3 {text-align: right; margin: -10px 20px 0 0; color: #5e96be}
h3 a {color: #5a5816}

.button {
	width: 105px;
	height: 31px;
	background: url(https://i.imgur.com/2WEhF.png) 0 0 no-repeat;
	display: block;
	color:  #000;
	font-size: 12px;
	line-height: 31px;
	text-decoration: none;
	position: absolute;
	left: 50%;
	bottom: 50px;
	margin-left: -53px;
}

.button.tweet {bottom: 100px; background-position: 0 -90px}
.button.fb {bottom: 150px; background-position: 0 -60px}

.info {position: absolute; right: 20px; bottom: 00px; margin: 0; color: green}

.info .key {
	width: 16px;
	height: 16px;
	background: url(https://i.imgur.com/2WEhF.png) no-repeat;
	text-indent: -9999px;
	display: inline-block;
}

.info .key.left {background-position: -92px -621px;}
.info .key.right {background-position: -92px -641px;}
