@import "https://fonts.googleapis.com/css?family=Gloria+Hallelujah";
* {
  box-sizing: border-box;
  overflow: hidden;
  -webkit-user-select: none !important;
  user-select: none !important;
  touch-action: manipulation !important;
  -webkit-touch-callout: none !important;
}

body {
  width: 100dvw;
  height: 100dvh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  -webkit-user-select: none !important;
  user-select: none !important;
  -webkit-touch-callout: none !important;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  overflow: hidden;
}

canvas {
  width: 100%;
  height: 100%;
  position: absolute;
}

#scoreBoard {
  z-index: -1;
  background: #b6c8dcb3;
  border-image: url("https://i.imgur.com/5BBsR.png") 100 5 round;
  width: 420px;
  height: 50px;
  position: absolute;
  top: -3px;
  left: 0;
}

#scoreBoard p {
  margin: 0 0 0 5px;
  padding: 0;
  font-size: 20px;
  line-height: 47px;
}

img {
  display: none;
}

#mainMenu, #gameOverMenu {
  text-align: center;
  z-index: 2;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#gameOverMenu {
  visibility: hidden;
}

h2, h3, h1 {
  font-weight: normal;
}

h1 {
  color: #5a5816;
  margin: 0;
  font-size: 60px;
  transform: rotate(-10deg);
}

h3 {
  text-align: right;
  color: #5e96be;
  margin: -10px 20px 0 0;
}

h3 a {
  color: #5a5816;
}

.button {
  color: #000;
  background: url("https://i.imgur.com/2WEhF.png") no-repeat;
  width: 105px;
  height: 31px;
  margin-left: -53px;
  font-size: 12px;
  line-height: 31px;
  text-decoration: none;
  display: block;
  position: absolute;
  bottom: 50px;
  left: 50%;
}

.button.tweet {
  background-position: 0 -90px;
  bottom: 100px;
}

.button.fb {
  background-position: 0 -60px;
  bottom: 150px;
}

.info {
  color: green;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.info .key {
  text-indent: -9999px;
  background: url("https://i.imgur.com/2WEhF.png") no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
}

.info .key.left {
  background-position: -92px -621px;
}

.info .key.right {
  background-position: -92px -641px;
}
/*# sourceMappingURL=index.781fe18c.css.map */
